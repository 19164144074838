import React from "react";
import PropTypes from "prop-types";
import { BreakPointer, Image, imageProps, Text, RichText } from "@atoms";
import CopyImageBlock from "../CopyImageBlock";
import StackedCard from "../StackedCard";
import CompanyLogo from "../CompanyLogo";
import ImageBlock from "../ImageBlock";

/* eslint react/prop-types: 0 */

export const Featured = ({ name, description, logo, image }) => {
  return (
    <div className="p-8 lg:p-16 lg:px-20">
      <BreakPointer
        watch={["md", "lg"]}
        render={bp => (
          <CopyImageBlock
            orientation="left"
            className="bg-green shadow-section"
            style={{ height: bp.lg ? 600 : 500 }}
            copy={() => (
              <StackedCard
                top={() => (
                  <CompanyLogo
                    logo={logo}
                    orientation="start"
                    className="h-32 md:h-40 -mb-6"
                  />
                )}
                bottom={() => (
                  <>
                    <Text tag="h3" use="label" className="text-green-3 mb-2">
                      {name}
                    </Text>
                    <RichText
                      html={description.html}
                      className="RichText--sm text-gray-5"
                    />
                  </>
                )}
                className="relative z-10 px-6 pb-6 sm:px-8 sm:pb-8 md:p-16"
              />
            )}
            image={
              bp.md
                ? ({ className, style }) => (
                    <ImageBlock
                      image={image}
                      className={className}
                      style={style}
                    />
                  )
                : null
            }
          />
        )}
      />
    </div>
  );
};

export const BackgroundImage = ({ image }) => {
  return (
    <div className="relative" style={{ height: "67vh", minHeight: 300 }}>
      <Image image={image} disableDrag />
    </div>
  );
};

Featured.propTypes = {
  name: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string,
  }).isRequired,
  logo: imageProps.isRequired,
  image: imageProps.isRequired,
};

BackgroundImage.propTypes = {
  image: imageProps.isRequired,
};

export default { Featured, BackgroundImage };
