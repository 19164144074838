import React from "react";
import PropTypes from "prop-types";
import { Container } from "@atoms";
import { useHomePageData } from "@staticQueries";
import CrossFader from "./CrossFader";
import Testimonial, { testimonialProps } from "./Testimonial";

const TestimonialFader = ({
  testimonials,
  currentSlide,
  setCurrentSlide,
  className,
  style,
}) => {
  let items = Array.isArray(testimonials) ? testimonials : [testimonials];

  // If passing a single testimonial object instead of array, then display without CrossFader
  if (items.length === 1) {
    return (
      <div className={className} style={style}>
        <Container size="md">
          <Testimonial
            {...items[0]}
            className="bg-white shadow-section pl-12 pr-8 py-6 md:px-20 md:py-12"
          />
        </Container>
      </div>
    );
  }

  // Else use CrossFader, and if the array is empty use home page testimonials
  // Note: home page testimonials are buried in a sub-property named `list`.
  const { testimonials: defaultItems } = useHomePageData();
  items = items.length === 0 ? defaultItems.list : items;

  return (
    <div className={className} style={style}>
      <Container size="md">
        <CrossFader
          className="bg-white shadow-section"
          interval={7000}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
        >
          {items.map((item, idx) => (
            <Testimonial
              key={idx}
              {...item}
              className="pl-12 pr-8 pt-6 md:px-20 md:pt-12"
            />
          ))}
        </CrossFader>
      </Container>
    </div>
  );
};

TestimonialFader.propTypes = {
  testimonials: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape(testimonialProps)),
    PropTypes.shape(testimonialProps),
  ]).isRequired,
  currentSlide: PropTypes.number,
  setCurrentSlide: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

TestimonialFader.defaultProps = {
  currentSlide: undefined,
  setCurrentSlide: undefined,
  className: "",
  style: {},
};

export default TestimonialFader;
