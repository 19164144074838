import React, { useState } from "react";
import classNames from "classnames";
import { useAppState } from "@state/state";
import PropTypes from "prop-types";
import { Icon } from "@atoms";

/**
 *
 * `ToggleCard` Card with front/back content that is toggled on click
 *
 */

// This rule just seems to never be satisfied
/* eslint jsx-a11y/click-events-have-key-events: 0 */

const ToggleCard = ({ front: Front, back: Back, className }) => {
  const [showingBack, setShowingBack] = useState(false);
  const [{ layout }] = useAppState();

  const handleMouseEvent = showBack => {
    if (!layout.isTouchDevice) {
      setShowingBack(showBack);
    }
  };

  const handleClickEvent = () => {
    if (layout.isTouchDevice) {
      setShowingBack(!showingBack);
    }
  };

  return (
    <div className={`HoverCard ${className}`}>
      <div
        role="button"
        tabIndex={0}
        className="relative flex flex-col w-full h-full outline-none focus:outline-none items-center justify-center"
        onMouseEnter={() => handleMouseEvent(true)}
        onMouseLeave={() => handleMouseEvent(false)}
        onClick={handleClickEvent}
      >
        <div
          className={classNames({
            "absolute inset-0 transition": true,
            "opacity-100 pointer-events-auto": !showingBack,
            "opacity-0 pointer-events-none": showingBack,
          })}
        >
          <Front />
        </div>

        <div
          className={classNames({
            "relative transition w-full h-full": true,
            "opacity-100 pointer-events-auto": showingBack,
            "opacity-0 pointer-events-none": !showingBack,
          })}
        >
          {layout.isTouchDevice && (
            <div className="absolute top-0 right-0 p-3 w-10 h-10">
              <Icon name="X" className="w-full h-full text-white" />
            </div>
          )}
          <Back />
        </div>
      </div>
    </div>
  );
};

ToggleCard.propTypes = {
  front: PropTypes.func.isRequired,
  back: PropTypes.func.isRequired,
  className: PropTypes.string,
};

ToggleCard.defaultProps = {
  className: "",
};

export default ToggleCard;
