import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps } from "@atoms";

/**
 *
 * `StackedCard` Card with top and bottom sections divided by SectionBreak
 *
 */

const Testimonial = ({ quote, person, title, photo, className }) => {
  return (
    <div className={`Testimonial ${className}`}>
      <div className="relative">
        <span
          style={{ fontSize: 64 }}
          className="absolute top-0 left-0 -translate-x-100 -ml-2 sm:-ml-3 -mt-2 font-mono font-bold text-green-3"
        >
          {`\u201C`}
        </span>

        <RichText html={quote.html} className="text-green mb-6" />

        <div className="flex items-end">
          <div className="relative w-12 h-12 rounded-full border-blue-1 overflow-hidden image-overflow-mask">
            <Image image={photo} />
          </div>

          <div className="ml-4">
            <Text
              tag="p"
              use="label"
              options={{
                size: "text-2xs",
              }}
              className="block text-green"
            >
              - {person}
            </Text>

            <Text
              tag="label"
              options={{ font: "font-mono", size: "text-2xs" }}
              className="text-green ml-4"
            >
              {title}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export const testimonialProps = {
  quote: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  person: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  photo: imageProps.isRequired,
};

Testimonial.propTypes = {
  ...testimonialProps,
  className: PropTypes.string,
};

Testimonial.defaultProps = {
  className: "",
};

export default Testimonial;
