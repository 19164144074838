import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Text, RichText, RawText, rawTextProps } from "@atoms";
import { Link, Button } from "@ui";

/**
 *
 * `CopyBlock` Common arrangement of headings, description, and CTA
 *
 */

const CopyBlock = ({
  heading,
  subheading,
  description,
  cta: CTA,
  ctaLink,
  ctaText,
  preset,
  className,
  style,
}) => {
  return (
    <div className={`CopyBlock ${className}`} style={style}>
      {subheading && (
        <Text tag="label" className="block text-green-3">
          {subheading}
        </Text>
      )}

      {heading.raw.length > 0 && (
        <RawText
          items={heading.raw}
          className={classNames({
            "mt-4": true,
            "text-white": preset === "light",
            "text-green": preset === "dark",
          })}
        />
      )}

      {description.html && (
        <RichText
          html={description.html}
          className={classNames({
            "mt-6 md:mt-8": true,
            "text-gray-3": preset === "light",
            "text-green": preset === "dark",
          })}
        />
      )}

      {/* If CTA render prop passed then use that, otherwise render a link button */}
      {CTA ? (
        <CTA className="mt-6 md:mt-8" />
      ) : (
        <>
          {ctaLink && ctaLink.url && (
            <Link
              to={ctaLink.url}
              target={ctaLink.target}
              className="inline-block mt-8 md:mt-10"
            >
              {/* Buttons text color presets are opposite */}
              <Button
                preset={preset === "light" ? "dark" : "light"}
                text={ctaText}
              />
            </Link>
          )}
        </>
      )}
    </div>
  );
};

CopyBlock.propTypes = {
  heading: PropTypes.shape({
    raw: rawTextProps,
  }),
  subheading: PropTypes.string,
  description: PropTypes.shape({
    html: PropTypes.string,
  }),
  ctaLink: PropTypes.shape({
    url: PropTypes.string,
    target: PropTypes.string,
  }),
  ctaText: PropTypes.string,
  cta: PropTypes.func,
  preset: PropTypes.oneOf(["dark", "light"]),
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

CopyBlock.defaultProps = {
  heading: { raw: [] },
  subheading: "",
  description: { html: null },
  ctaLink: null,
  ctaText: null,
  cta: null,
  preset: "light",
  className: "",
  style: {},
};

export default CopyBlock;
