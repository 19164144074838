import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

/**
 *
 * `CopyImageBlock` Side-by-side copy and image blocks
 *
 */

const CopyImageBlock = ({
  copy: Copy,
  image: Image,
  orientation,
  className,
  style,
}) => {
  return (
    <div
      className={classNames(
        {
          "relative h-full flex flex-col": true,
          "md:flex-row-reverse": orientation === "left",
          "md:flex-row": orientation === "right",
        },
        className
      )}
      style={style}
    >
      {Image && (
        <div className="flex-1 md:flex flex-col justify-center md:w-1/2 mb-4 sm:mb-6 md:mb-0">
          <Image
            className={classNames({
              "md:-mr-16 xl:-mr-20": orientation === "left",
              "md:-ml-16 xl:-ml-20": orientation === "right",
            })}
            style={{
              paddingBottom: `${(9 / 16) * 100}%`,
              maxHeight: "80%",
            }}
          />
        </div>
      )}

      <div className="w-full h-full md:w-1/2 flex flex-col justify-center">
        <Copy
          className={classNames({
            "md:pr-16 lg:pr-20": orientation === "left",
            "md:pl-16 lg:pl-20": orientation === "right",
          })}
        />
      </div>
    </div>
  );
};

CopyImageBlock.propTypes = {
  copy: PropTypes.func.isRequired,
  image: PropTypes.func,
  orientation: PropTypes.oneOf(["left", "right"]),
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

CopyImageBlock.defaultProps = {
  image: null,
  orientation: "left",
  className: "",
  style: {},
};

export default CopyImageBlock;
