import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import CarouselNav from "./CarouselNav";

const Carousel = ({
  children,
  infinite,
  stagePadding,
  showButtons,
  showDots,
  overflowVisible,
  initialSlide,
  onSlideChange,
  className,
}) => {
  const [methods, setMethods] = useState({});
  const [height, setHeight] = useState("auto");
  const [currentSlide, setCurrentSlide] = useState(initialSlide);
  const el = useRef({});

  const slides = React.Children.map(children, child =>
    React.cloneElement(child, {
      ...child.props,
    })
  );

  useEffect(() => {
    const { slideTo, slideNext, slidePrev } = el.current;
    setMethods({
      slideTo,
      slideNext,
      slidePrev,
    });
  }, []);

  useEffect(() => {
    if (initialSlide !== currentSlide) {
      methods.slideTo(initialSlide);
    }
  }, [initialSlide]);

  const doSetHeight = () => {
    // this is a hack to set the height in px of the carousel
    // need timeout for carousel to render
    setTimeout(() => {
      if (el.current && el.current.rootComponent) {
        const firstSlide = el.current.rootComponent.querySelectorAll(
          ".alice-carousel__stage-item > div"
        )[0];
        setHeight(`${firstSlide.offsetHeight}px`);
      }
    }, 100);
  };

  const handleSlideChange = e => {
    setCurrentSlide(e.item);
    if (onSlideChange) {
      onSlideChange(e.item);
    }
  };

  return (
    <>
      <div
        className={classNames(
          {
            "Carousel relative": true,
            "Carousel--overflow-visible": overflowVisible,
          },
          className
        )}
        style={{
          paddingLeft: stagePadding,
          paddingRight: stagePadding,
          height,
        }}
      >
        <AliceCarousel
          ref={el}
          mouseTrackingEnabled
          preventEventOnTouchMove
          dotsDisabled
          buttonsDisabled
          infinite={infinite}
          onInitialized={doSetHeight}
          onResized={doSetHeight}
          onSlideChanged={handleSlideChange}
        >
          {slides}
        </AliceCarousel>

        <CarouselNav
          showButtons={showButtons}
          showDots={showDots}
          currentSlide={currentSlide}
          totalSlides={slides.length}
          infinite={infinite}
          slideTo={methods.slideTo}
          slideNext={methods.slideNext}
          slidePrev={methods.slidePrev}
        />
      </div>
    </>
  );
};

Carousel.propTypes = {
  children: PropTypes.node,
  infinite: PropTypes.bool,
  stagePadding: PropTypes.number,
  showButtons: PropTypes.bool,
  showDots: PropTypes.bool,
  overflowVisible: PropTypes.bool,
  initialSlide: PropTypes.number,
  onSlideChange: PropTypes.func,
  className: PropTypes.string,
};

Carousel.defaultProps = {
  children: [],
  infinite: true,
  stagePadding: 0,
  showButtons: true,
  showDots: false,
  overflowVisible: false,
  initialSlide: 0,
  onSlideChange: null,
  className: "",
};

export default Carousel;
