import React from "react";
import PropTypes from "prop-types";
import { SectionBreak } from "@atoms";

/**
 *
 * `StackedCard` Card with top and bottom sections divided by SectionBreak
 *
 */

const StackedCard = ({ top: Top, bottom: Bottom, className }) => {
  return (
    <div className={`StackedCard flex flex-col ${className}`}>
      <Top />

      <SectionBreak className="my-6 text-green-3" />

      <Bottom />
    </div>
  );
};

StackedCard.propTypes = {
  top: PropTypes.func.isRequired,
  bottom: PropTypes.func.isRequired,
  className: PropTypes.string,
};

StackedCard.defaultProps = {
  className: "",
};

export default StackedCard;
