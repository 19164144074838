import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Image, imageProps } from "@atoms";

const CompanyLogo = ({
  logo,
  fillRatio,
  pad,
  maxSize,
  orientation,
  className,
}) => {
  const el = useRef();
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  // const { width, height } = image;
  // const isSquarish = width / height < 1.5;

  useEffect(() => {
    if (!el.current) {
      return;
    }

    // Get the width and height of the container
    const { clientWidth, clientHeight } = el.current;

    // Get the width/height ratio of the image
    const imageRatio = logo.width / logo.height;

    // Get the desired size that we want the image to fill up
    // within the container.
    const heightPenalty = imageRatio < 2 ? 0.75 : 1;
    const fillSize = clientWidth * clientHeight * fillRatio * heightPenalty;

    // A little algebra to calculate imageHeight and width
    // based on the fill size
    let imageHeight = Math.sqrt(fillSize / imageRatio);
    let imageWidth = imageHeight * imageRatio;

    // Resize imageWidth if it's larger than the image file's width
    // or if it's larger than the container width
    imageWidth = Math.min(logo.width, imageWidth);
    imageWidth = Math.min(clientWidth * maxSize, imageWidth);

    // Reset imageHeight based on new imageWidth, then do the same
    // resize checks on that height
    imageHeight = imageWidth / imageRatio;
    imageHeight = Math.min(logo.height, imageHeight);
    imageHeight = Math.min(clientHeight * maxSize, imageHeight);

    // Set the final imageWidth based on new imageHeight
    imageWidth = imageHeight * imageRatio;

    // Save dimensions to state
    setImageDimensions({ width: imageWidth, height: imageHeight });
  }, []);

  const { width: imageWidth, height: imageHeight } = imageDimensions;

  return (
    <div
      className={`flex flex-col ${className}`}
      style={{ padding: `${pad}rem` }}
    >
      <div
        ref={el}
        className={`w-full h-full flex items-center justify-${orientation}`}
      >
        {imageWidth > 0 && imageHeight > 0 && (
          <div
            className="relative"
            style={{ width: imageWidth, height: imageHeight }}
          >
            <Image image={logo} showLQIP={false} objectFit="contain" />
          </div>
        )}
      </div>
    </div>
  );
};

CompanyLogo.propTypes = {
  logo: imageProps.isRequired,
  fillRatio: PropTypes.number,
  pad: PropTypes.number,
  maxSize: PropTypes.number,
  orientation: PropTypes.oneOf(["start", "center", "end"]),
  className: PropTypes.string,
};

CompanyLogo.defaultProps = {
  fillRatio: 0.3,
  pad: 1,
  maxSize: 0.7,
  orientation: "center",
  className: "",
};

export default CompanyLogo;
