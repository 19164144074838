import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Icon } from "@atoms";

const CrossFader = ({
  children,
  currentSlide: _currentSlide,
  setCurrentSlide: _setCurrentSlide,
  interval,
  nav,
  className,
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const el = useRef();
  const timeout = useRef();

  const curSlide = _currentSlide || currentSlide;
  const setSlide = _setCurrentSlide || setCurrentSlide;

  useEffect(() => {
    if (!interval) {
      return undefined;
    }

    if (timeout.current) {
      clearTimeout(timeout.current);
    }

    timeout.current = setTimeout(() => {
      const nextSlide = curSlide === children.length - 1 ? 0 : curSlide + 1;
      setSlide(nextSlide);
    }, interval);

    return () => clearTimeout(timeout.current);
  }, [curSlide]);

  return (
    <div className={`relative ${className}`}>
      <div className="flex flex-col">
        <div className="flex-1 overflow-hidden">
          <div
            ref={el}
            className="relative flex whitespace-nowrap"
            style={{ width: `${100 * children.length + 1}%` }}
          >
            {children.map((child, idx) => (
              <div
                key={idx}
                className={classNames({
                  "w-full flex flex-col justify-center transition-slow": true,
                  "opacity-100": idx === curSlide,
                  "opacity-0": idx !== curSlide,
                })}
                style={{ transform: `translateX(-${100 * idx}%)` }}
              >
                {child}
              </div>
            ))}
          </div>
        </div>

        {nav === "dots" && (
          <div className="flex justify-center items-center h-12">
            {Array(children.length)
              .fill()
              .map((item, idx) => (
                <button
                  key={idx}
                  type="button"
                  className={classNames({
                    "outline-none focus:outline-none h-2 w-2 rounded-full mx-1 transition": true,
                    "bg-green": idx === curSlide,
                    "bg-gray-3": idx !== curSlide,
                  })}
                  onClick={() => setSlide(idx)}
                >
                  <span className="hidden">{idx}</span>
                </button>
              ))}
          </div>
        )}

        {nav === "arrows" && (
          <>
            <button
              type="button"
              className={classNames({
                "outline-none focus:outline-none absolute bg-green-3 text-green-1 hover:text-white transition w-10 h-10 rounded-full flex items-center justify-center left-6 lg:left-20": true,
              })}
              style={{
                top: "50%",
                transform: "translate(-50%, -50%)",
              }}
              onClick={() =>
                setSlide(curSlide === 0 ? children.length - 1 : curSlide - 1)
              }
            >
              <Icon
                name="Arrow"
                className="w-8 h-3"
                style={{ transform: "rotate(180deg)" }}
              />
            </button>
            <button
              type="button"
              className={classNames({
                "outline-none focus:outline-none absolute bg-green-3 text-green-1 hover:text-white transition w-10 h-10 rounded-full flex items-center justify-center right-6 lg:right-20": true,
              })}
              style={{
                top: "50%",
                transform: "translate(50%, -50%)",
              }}
              onClick={() =>
                setSlide(curSlide === children.length - 1 ? 0 : curSlide + 1)
              }
            >
              <Icon name="Arrow" className="w-8 h-3" />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

CrossFader.propTypes = {
  children: PropTypes.node.isRequired,
  currentSlide: PropTypes.number,
  setCurrentSlide: PropTypes.func,
  interval: PropTypes.number,
  nav: PropTypes.oneOf(["dots", "arrows"]),
  className: PropTypes.string,
};

CrossFader.defaultProps = {
  currentSlide: null,
  setCurrentSlide: null,
  interval: null,
  nav: "dots",
  className: "",
};

export default CrossFader;
