import React from "react";
import PropTypes from "prop-types";
import { StaggerIn, RichText, imageProps } from "@atoms";
import classNames from "classnames";
import ToggleCard from "./ToggleCard";
import CompanyLogo from "./CompanyLogo";

const CompanyGrid = ({ companies, className }) => {
  return (
    <div className={className}>
      <StaggerIn className="grid w-full grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-12 auto-rows-fr">
        {companies.map(company => (
          <a
            key={company.id}
            href={
              company.companyUrl
                ? `https://${company?.companyUrl
                    ?.replace("https://", "")
                    ?.replace("http://", "")}`
                : null
            }
            target="_blank"
            rel="noreferrer noopener"
            className={classNames("block relative w-full shadow-image", {
              "cursor-pointer": company?.companyUrl,
            })}
          >
            {company.status && (
              <span
                className={classNames(
                  "p-2 text-3xs h-12 text-center w-full relative z-10 flex items-center justify-center flex-col",
                  {
                    "bg-gray-3": company.exited,
                    "bg-gray-4": !company.exited,
                  }
                )}
              >
                <span className="mb-1 font-bold">{company.status}</span>
                <span>{company.statusSubtitle}</span>
              </span>
            )}
            <ToggleCard
              className={classNames("flex-grow h-full", {
                "max-h-64": company.status,
                "max-h-76": !company.status,
              })}
              front={() => (
                <div
                  className={classNames(
                    "flex-grow flex flex-col w-full items-center justify-center",
                    {
                      "pt-12": !company.status,
                    }
                  )}
                >
                  <CompanyLogo logo={company.logo} className="w-full h-32" />
                  {company.tagline && (
                    <p className="text-2xs text-center w-full py-2 px-3 leading-tight text-gray-2">
                      {company.tagline}
                    </p>
                  )}
                </div>
              )}
              back={() => (
                <div
                  className={classNames(
                    "flex-grow w-full h-full bg-green p-4 text-left overflow-auto",
                    {
                      "pt-12": !company.status,
                    }
                  )}
                >
                  <RichText
                    html={
                      company.short_description?.html ||
                      company.shortDescription?.html ||
                      company.description.html
                    }
                    className="RichText--2xs leading-tight text-white"
                  />
                </div>
              )}
            />
          </a>
        ))}
      </StaggerIn>
    </div>
  );
};

CompanyGrid.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      logo: imageProps.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.shape({
        html: PropTypes.string,
      }).isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

CompanyGrid.defaultProps = {
  className: "",
};

export default CompanyGrid;
