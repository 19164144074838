import React from "react";
import PropTypes from "prop-types";
import { StaggerIn } from "@atoms";

/**
 *
 * `CardGroup` wrapping container with InVeiw animation for Cards
 *
 */

const CardGroup = ({ cards, itemsWidthClassName, gutter, className }) => {
  return (
    <StaggerIn className={`flex flex-wrap -mx-${gutter} ${className}`}>
      {cards.map((Item, idx) => (
        <Item
          key={idx}
          className={`CardGroup__Card ${itemsWidthClassName} p-${gutter} opacity-0`}
        />
      ))}
    </StaggerIn>
  );
};

CardGroup.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.func),
  itemsWidthClassName: PropTypes.string,
  gutter: PropTypes.number,
  className: PropTypes.string,
};

CardGroup.defaultProps = {
  cards: [],
  itemsWidthClassName: "w-full sm:w-1/2 md:w-1/3",
  gutter: 4,
  className: "",
};

export default CardGroup;
