import React from "react";
import PropTypes from "prop-types";
import { Image, imageProps } from "@atoms";

/**
 *
 * `ImageBlock` Styled image for CopyImageBlock
 *
 */

const ImageBlock = ({ image, className, style }) => {
  return (
    <div
      className={`ImageBlock relative h-full ${className}`}
      style={{ ...style }}
    >
      <div className="absolute inset-0 shadow-image">
        <Image image={image} disableDrag />
      </div>
    </div>
  );
};

ImageBlock.propTypes = {
  image: imageProps.isRequired,
  className: PropTypes.string,
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
};

ImageBlock.defaultProps = {
  className: "",
  style: {},
};

export default ImageBlock;
